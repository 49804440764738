<template>
  <div id="alreadyQuoted">
    <van-nav-bar title="订单详情" left-arrow @click-left="goSubPage('home')" :border="false" />
    <ProductInfor :needButton="false" />
  </div>
</template>
<script>
import ProductInfor from "@/components/order/ProductInfor.vue";
export default {
  data() {
    return {
      OrderDetails: {},
    };
  },
  components: {
    ProductInfor,
  },
  methods: {
    goExpectPrice() {
      this.$router.push({
        name: "ExpectedPrice",
        params: { pid: this.OrderDetails.id },
      });
    },
  },
};
</script>
<style lang="less">
#loading {
  height: 100vh;
  width: 100vw;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
#alreadyQuoted {
  background: #f0f0f0;
  padding-bottom: 47px;
  // margin-bottom: 46px;
  .pageGradient1 {
    width: 100%;
    height: 20rem;
    background: linear-gradient(to bottom, #ff7963, #ff816c00);
  }
  .alreadyTitle {
    width: 100%;
    .typeWrap {
      width: 95%;
      background: #fff;
      border-radius: 10px;
      margin: 0 auto;
      align-items: left;
      padding: 2rem 2rem;
      box-sizing: border-box;
      box-shadow: -1px 0px 6px 0 #f0f0f0;
      .info {
        color: #000;
        font-size: 1.4rem;
        padding: 0.5rem 0;
      }
      .des {
        padding-left: 1rem;
        font-size: 1.4rem;
      }
    }
    .type {
      font-size: 2.5rem;
      font-weight: 600;
      margin: 1.5rem;
    }
    color: #fff;
    position: absolute;
    top: 6rem;
    left: 0;
    right: 0;
  }
  .topTitle {
    font-size: 1.8rem;
    font-weight: 400;
    color: #000;
  }

  .bottomButton {
    width: 100%;
    // margin-bottom: 160px;
    // margin-bottom: 60px;
    background: linear-gradient(to right, #ff6034, #ee0a24);
    height: 50px;
    font-size: 1.7rem;
    // font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 50px;
  }
}
</style>